import * as E from "./queryEngine";

export const query2NecessaryTags = (q: E.Test) => {
  const inner = (tags: any[]) => {
    tags.forEach(tag => {
      if (Array.isArray(tag)) {
        inner(tag);
      } else {
        res.push(tag);
      }
    });
  };

  let res = [];
  const i = intermediate(q);
  if (i === null) {
    return [];
  }
  inner(i);
  return res.filter(d => d != null);
};

const intermediate = (q: E.Test) => {
  if (q == null) {
    return null;
  }
  switch (q.test) {
    case "map": {
      return intermediate(q.child);
    }
    case "array": {
      return intermediate(q.child);
    }
    case "and": {
      return q.children.map(intermediate);
    }
    case "or": {
      return q.children.map(intermediate);
      //   return q.children.length < 1 ? null : query2NecessaryTags(q.children[0]);
    }
    case "negation": {
      return null;
    }
    case "like": {
      return q.value;
    }
    case "equal": {
      return q.value;
    }
    default: {
      return null;
    }
  }
};
