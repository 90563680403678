// Generated from QueryLang.gr by ANTLR 4.7.3-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { QueryLangListener } from "./QueryLangListener";
import { QueryLangVisitor } from "./QueryLangVisitor";


export class QueryLangParser extends Parser {
	public static readonly AND = 1;
	public static readonly OR = 2;
	public static readonly NOT = 3;
	public static readonly LPAREN = 4;
	public static readonly RPAREN = 5;
	public static readonly TAG = 6;
	public static readonly WS = 7;
	public static readonly RULE_query = 0;
	public static readonly RULE_alternativeExpression = 1;
	public static readonly RULE_expression = 2;
	public static readonly RULE_binary = 3;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"query", "alternativeExpression", "expression", "binary",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'and'", "'or'", "'not'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "AND", "OR", "NOT", "LPAREN", "RPAREN", "TAG", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(QueryLangParser._LITERAL_NAMES, QueryLangParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return QueryLangParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "QueryLang.gr"; }

	// @Override
	public get ruleNames(): string[] { return QueryLangParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return QueryLangParser._serializedATN; }

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(QueryLangParser._ATN, this);
	}
	// @RuleVersion(0)
	public query(): QueryContext {
		let _localctx: QueryContext = new QueryContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, QueryLangParser.RULE_query);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 8;
			this.alternativeExpression();
			this.state = 9;
			this.match(QueryLangParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public alternativeExpression(): AlternativeExpressionContext {
		let _localctx: AlternativeExpressionContext = new AlternativeExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, QueryLangParser.RULE_alternativeExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 12;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			do {
				{
				{
				this.state = 11;
				this.expression(0);
				}
				}
				this.state = 14;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			} while ((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << QueryLangParser.NOT) | (1 << QueryLangParser.LPAREN) | (1 << QueryLangParser.TAG))) !== 0));
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 4;
		this.enterRecursionRule(_localctx, 4, QueryLangParser.RULE_expression, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 24;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case QueryLangParser.LPAREN:
				{
				_localctx = new ParenExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 17;
				this.match(QueryLangParser.LPAREN);
				this.state = 18;
				(_localctx as ParenExpressionContext)._expr = this.alternativeExpression();
				this.state = 19;
				this.match(QueryLangParser.RPAREN);
				}
				break;
			case QueryLangParser.NOT:
				{
				_localctx = new NotExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 21;
				this.match(QueryLangParser.NOT);
				this.state = 22;
				(_localctx as NotExpressionContext)._expr = this.expression(3);
				}
				break;
			case QueryLangParser.TAG:
				{
				_localctx = new IdentifierExpressionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 23;
				this.match(QueryLangParser.TAG);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 32;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new BinaryExpressionContext(new ExpressionContext(_parentctx, _parentState));
					(_localctx as BinaryExpressionContext)._left = _prevctx;
					this.pushNewRecursionContext(_localctx, _startState, QueryLangParser.RULE_expression);
					this.state = 26;
					if (!(this.precpred(this._ctx, 2))) {
						throw new FailedPredicateException(this, "this.precpred(this._ctx, 2)");
					}
					this.state = 27;
					(_localctx as BinaryExpressionContext)._op = this.binary();
					this.state = 28;
					(_localctx as BinaryExpressionContext)._right = this.expression(3);
					}
					}
				}
				this.state = 34;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public binary(): BinaryContext {
		let _localctx: BinaryContext = new BinaryContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, QueryLangParser.RULE_binary);
		try {
			this.state = 37;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case QueryLangParser.AND:
				_localctx = new AndBinaryContext(_localctx);
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 35;
				this.match(QueryLangParser.AND);
				}
				break;
			case QueryLangParser.OR:
				_localctx = new OrBinaryContext(_localctx);
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 36;
				this.match(QueryLangParser.OR);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 2:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 2);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\t*\x04\x02\t" +
		"\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x03\x02\x03\x02\x03\x02" +
		"\x03\x03\x06\x03\x0F\n\x03\r\x03\x0E\x03\x10\x03\x04\x03\x04\x03\x04\x03" +
		"\x04\x03\x04\x03\x04\x03\x04\x03\x04\x05\x04\x1B\n\x04\x03\x04\x03\x04" +
		"\x03\x04\x03\x04\x07\x04!\n\x04\f\x04\x0E\x04$\v\x04\x03\x05\x03\x05\x05" +
		"\x05(\n\x05\x03\x05\x02\x02\x03\x06\x06\x02\x02\x04\x02\x06\x02\b\x02" +
		"\x02\x02\x02*\x02\n\x03\x02\x02\x02\x04\x0E\x03\x02\x02\x02\x06\x1A\x03" +
		"\x02\x02\x02\b\'\x03\x02\x02\x02\n\v\x05\x04\x03\x02\v\f\x07\x02\x02\x03" +
		"\f\x03\x03\x02\x02\x02\r\x0F\x05\x06\x04\x02\x0E\r\x03\x02\x02\x02\x0F" +
		"\x10\x03\x02\x02\x02\x10\x0E\x03\x02\x02\x02\x10\x11\x03\x02\x02\x02\x11" +
		"\x05\x03\x02\x02\x02\x12\x13\b\x04\x01\x02\x13\x14\x07\x06\x02\x02\x14" +
		"\x15\x05\x04\x03\x02\x15\x16\x07\x07\x02\x02\x16\x1B\x03\x02\x02\x02\x17" +
		"\x18\x07\x05\x02\x02\x18\x1B\x05\x06\x04\x05\x19\x1B\x07\b\x02\x02\x1A" +
		"\x12\x03\x02\x02\x02\x1A\x17\x03\x02\x02\x02\x1A\x19\x03\x02\x02\x02\x1B" +
		"\"\x03\x02\x02\x02\x1C\x1D\f\x04\x02\x02\x1D\x1E\x05\b\x05\x02\x1E\x1F" +
		"\x05\x06\x04\x05\x1F!\x03\x02\x02\x02 \x1C\x03\x02\x02\x02!$\x03\x02\x02" +
		"\x02\" \x03\x02\x02\x02\"#\x03\x02\x02\x02#\x07\x03\x02\x02\x02$\"\x03" +
		"\x02\x02\x02%(\x07\x03\x02\x02&(\x07\x04\x02\x02\'%\x03\x02\x02\x02\'" +
		"&\x03\x02\x02\x02(\t\x03\x02\x02\x02\x06\x10\x1A\"\'";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!QueryLangParser.__ATN) {
			QueryLangParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(QueryLangParser._serializedATN));
		}

		return QueryLangParser.__ATN;
	}

}

export class QueryContext extends ParserRuleContext {
	public alternativeExpression(): AlternativeExpressionContext {
		return this.getRuleContext(0, AlternativeExpressionContext);
	}
	public EOF(): TerminalNode { return this.getToken(QueryLangParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return QueryLangParser.RULE_query; }
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterQuery) {
			listener.enterQuery(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitQuery) {
			listener.exitQuery(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitQuery) {
			return visitor.visitQuery(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AlternativeExpressionContext extends ParserRuleContext {
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return QueryLangParser.RULE_alternativeExpression; }
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterAlternativeExpression) {
			listener.enterAlternativeExpression(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitAlternativeExpression) {
			listener.exitAlternativeExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitAlternativeExpression) {
			return visitor.visitAlternativeExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return QueryLangParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class ParenExpressionContext extends ExpressionContext {
	public _expr: AlternativeExpressionContext;
	public LPAREN(): TerminalNode { return this.getToken(QueryLangParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(QueryLangParser.RPAREN, 0); }
	public alternativeExpression(): AlternativeExpressionContext {
		return this.getRuleContext(0, AlternativeExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterParenExpression) {
			listener.enterParenExpression(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitParenExpression) {
			listener.exitParenExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitParenExpression) {
			return visitor.visitParenExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class NotExpressionContext extends ExpressionContext {
	public _expr: ExpressionContext;
	public NOT(): TerminalNode { return this.getToken(QueryLangParser.NOT, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterNotExpression) {
			listener.enterNotExpression(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitNotExpression) {
			listener.exitNotExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitNotExpression) {
			return visitor.visitNotExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class BinaryExpressionContext extends ExpressionContext {
	public _left: ExpressionContext;
	public _op: BinaryContext;
	public _right: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public binary(): BinaryContext {
		return this.getRuleContext(0, BinaryContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterBinaryExpression) {
			listener.enterBinaryExpression(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitBinaryExpression) {
			listener.exitBinaryExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitBinaryExpression) {
			return visitor.visitBinaryExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class IdentifierExpressionContext extends ExpressionContext {
	public TAG(): TerminalNode { return this.getToken(QueryLangParser.TAG, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterIdentifierExpression) {
			listener.enterIdentifierExpression(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitIdentifierExpression) {
			listener.exitIdentifierExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitIdentifierExpression) {
			return visitor.visitIdentifierExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class BinaryContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return QueryLangParser.RULE_binary; }
	public copyFrom(ctx: BinaryContext): void {
		super.copyFrom(ctx);
	}
}
export class AndBinaryContext extends BinaryContext {
	public AND(): TerminalNode { return this.getToken(QueryLangParser.AND, 0); }
	constructor(ctx: BinaryContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterAndBinary) {
			listener.enterAndBinary(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitAndBinary) {
			listener.exitAndBinary(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitAndBinary) {
			return visitor.visitAndBinary(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class OrBinaryContext extends BinaryContext {
	public OR(): TerminalNode { return this.getToken(QueryLangParser.OR, 0); }
	constructor(ctx: BinaryContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: QueryLangListener): void {
		if (listener.enterOrBinary) {
			listener.enterOrBinary(this);
		}
	}
	// @Override
	public exitRule(listener: QueryLangListener): void {
		if (listener.exitOrBinary) {
			listener.exitOrBinary(this);
		}
	}
	// @Override
	public accept<Result>(visitor: QueryLangVisitor<Result>): Result {
		if (visitor.visitOrBinary) {
			return visitor.visitOrBinary(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


