import { List } from "immutable";
import { Block, Value } from "slate";
import { Editor } from "slate-react";

import * as U from "./utils";
import { BlockType } from "../schema";

export const decreaseItemDepth = (ed: Editor) => {
  const depth = U.getItemDepth(ed.value);
  if (depth === 1) {
    return;
  }

  const currentItem = U.getCurrentItem(ed.value);
  if (!currentItem) {
    return;
  }

  const currentList = ed.value.document.getParent(currentItem.key) as Block;
  const parentItem = ed.value.document.getParent(currentList.key) as Block;
  const parentList = ed.value.document.getParent(parentItem.key) as Block;

  const rest = currentList.nodes.skipUntil(i => i === currentItem);

  ed.withoutNormalizing(() => {
    rest.reverse().forEach(n => {
      ed.moveNodeByKey(
        n.key,
        parentList.key,
        parentList.nodes.indexOf(parentItem) + 1
      );
    });

    if (currentList.nodes.size === rest.size) {
      ed.removeNodeByKey(currentList.key);
    }
  });
};

export const increaseItemDepth = (ed: Editor) => {
  const currentItem = U.getCurrentItem(ed.value);
  // ed.withoutNormalizing(() => {
  //   ed.wrapBlockByKey(currentItem.key, BlockType.ListItem);
  //   ed.wrapBlockByKey(currentItem.key, BlockType.ListUnordered);
  //   // const toNormalize = ed.value.document.getParent(
  //   //   ed.value.document.getPath(currentItem).pop()
  //   // );
  //   // console.log("tonorm", toNormalize);
  // });

  const previousItem = U.getPreviousItem(ed.value);

  if (!previousItem || !currentItem) {
    return;
  }

  const lastChild = previousItem.nodes.last() as Block; // should have 0 or 1 elements
  const prevItemSubl = U.isList(lastChild) && lastChild;

  if (prevItemSubl) {
    ed.moveNodeByKey(
      currentItem.key,
      prevItemSubl.key,
      prevItemSubl.nodes.size
    );
    return;
  }

  const currentList = U.getListForItem(ed.value, previousItem);
  const newSublist = Block.create({ type: currentList.type });

  ed.withoutNormalizing(() => {
    const lastIndex = previousItem.nodes.size;
    ed.insertNodeByKey(previousItem.key, lastIndex, newSublist);
    ed.moveNodeByKey(currentItem.key, newSublist.key, 0);
  });
};

export const splitListItem = (ed: Editor) => {
  const currentItem = U.getCurrentItem(ed.value);
  if (!currentItem) {
    return;
  }
  ed.splitDescendantsByKey(
    currentItem.key,
    ed.value.selection.start.key,
    ed.value.selection.start.offset
  );
};

const getHighestSelectedBlocks = (v: Value): List<Block> => {
  const startBlock = v.document.getClosestBlock(v.selection.start.key);
  const endBlock = v.document.getClosestBlock(v.selection.end.key);

  if (startBlock === endBlock) {
    return List([startBlock]);
  }

  const ancestor = (v.document as any).getCommonAncestor(
    startBlock.key,
    endBlock.key
  );

  const startPath = ancestor.getPath(startBlock.key);
  const endPath = ancestor.getPath(endBlock.key);

  return ancestor.nodes.slice(startPath[0], endPath[0] + 1);
};

export const wrapInList = (ed: Editor, type?: BlockType) => {
  const selectedBlocks = getHighestSelectedBlocks(ed.value);

  type = type || BlockType.ListUnordered;

  ed.withoutNormalizing(() => {
    ed.wrapBlock(Block.create({ type }));

    selectedBlocks.forEach(n => {
      if (U.isList(n)) {
        n.nodes.forEach((z: Block) => ed.unwrapNodeByKey(z.key));
      } else {
        ed.wrapBlockByKey(n.key, BlockType.ListItem);
      }
    });
  });
};

export const unWrapList = (ed: Editor) => {
  const items = U.getItemsAtRange(ed.value);
  if (items.isEmpty()) {
    return;
  }

  ed.withoutNormalizing(() => {
    items.forEach(i => ed.unwrapNodeByKey(i.key));

    const firstItem = items.first() as Block;
    const parent = ed.value.document.getParent(firstItem.key) as Block;

    let index = parent.nodes.findIndex((b: Block) => b.key === firstItem.key);

    items.forEach(i => {
      i.nodes.forEach((b: Block) => {
        ed.moveNodeByKey(b.key, parent.key, index);
        index += 1;
      });
    });

    items.forEach(i => ed.removeNodeByKey(i.key));
  });
};

export const setListType = (ed: Editor, type: BlockType) => {
  const currentItem = U.getCurrentList(ed.value);
  if (!currentItem) {
    return;
  }

  ed.setNodeByKey(currentItem.key, { type });
};
