import * as React from "react";
import { RenderInlineProps, Editor, Plugin } from "slate-react";
import styled from "@emotion/styled";
import { Inline } from "slate";
import { InlineType } from "../schema";

// TODO: fix
// const s_regex = /(?<=^|\s)#([a-z\d-._]*)(?=\s)/gi;
const s_regex = /#([a-z\d-._]*)(?=\s)/gi;

const onChange = (ed: Editor, next: any) => {
  if (!ed.value.startText) {
    return;
  }
  const startOffset = ed.value.selection.start.offset;
  const textBefore = ed.value.startText.text.slice(0, startOffset);
  const result = s_regex.exec(textBefore);
  if (!result) {
    return next();
  }
  const i = Inline.create({
    type: InlineType.Tag,
    data: { text: `${result[1]}` }
  } as any);

  ed.deleteBackward(result[0].length + 1);
  ed.insertInlineAtRange(ed.value.selection, i);
  ed.insertText(" ");
};

const SimpleTag = styled.span`
  background-color: rgb(140, 217, 177, 0.78);
  border-radius: 3px;
  padding: 0px 0.2em;
  // margin: 0px 0.2em;
`;

const renderInline = (p: RenderInlineProps, editor: Editor, next: any) => {
  const onClick = () =>
    (editor.props as any).setSearchQuery(p.node.data.get("text"));
  switch (p.node.type) {
    case InlineType.Tag: {
      return (
        <SimpleTag onClick={onClick} {...p.attributes}>
          {p.node.data.get("text")}
        </SimpleTag>
      );
    }
    default: {
      return next();
    }
  }
};

export const TagPlugin = {
  renderInline,
  onChange
} as Plugin;
