import * as T from "./types";
import * as Im from "immutable";
import { Value } from "slate";
import { getCookieValue } from "utils/misc";

const defaultExtra = {
  headers: {
    "Content-Type": "application/json"
  }
};

const json2doc = (orig: any, with_body: boolean = false) => {
  let doc = T.Document(orig);
  doc = doc.set("metadata", Im.Map(JSON.parse(orig.metadata)));
  doc = doc.update("metadata", m => m.map(a => Im.Set(a)));
  doc = doc.set("created", new Date(orig.created));
  doc = doc.set("last_modified", new Date(orig.last_modified));
  if (with_body) {
    doc = doc.set("body", Value.fromJSON(JSON.parse(orig["body"])));
  } else {
    doc = doc.set("body", null);
  }

  return doc;
};

export async function fetchMeta() {
  const extra = { ...defaultExtra };
  const resp = await fetch("/api/document/all_meta", extra);
  const json = await resp.json();
  const list = json.map(v => json2doc(v, false));

  return Im.List(list) as T.TDocumentCollection;
}

export async function fetchDoc(id: string) {
  const extra = { ...defaultExtra };
  const resp = await fetch(`/api/document/one/${id}`, extra);
  const orig = await resp.json();
  return json2doc(orig, true);
}

export async function patchDoc(doc: T.TDocument) {
  const body = doc.toJS();
  body["body"] = doc.body.toJSON();

  const extra = {
    ...defaultExtra,
    body: JSON.stringify(body),
    method: "PUT"
  };
  // const resp = await fetch(`/document?id=eq.${doc.id}`, extra);
  const resp = await fetch(`/api/document/one/${doc.id}`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't update document");
  }
  return null;
}

export async function createDoc(doc: T.TDocument) {
  const body = doc.toJS();
  body["body"] = doc.body.toJSON();
  delete body["id"];
  const extra = {
    ...defaultExtra,
    body: JSON.stringify(body),
    method: "POST"
  };

  const resp = await fetch(`/api/document/create`, extra);
  const respJ = await resp.json();
  const location = respJ["id"];
  if (!(resp.ok && location)) {
    throw new Error("Couldn't create new document");
  }
  return location;
}

export async function deleteDoc(doc: T.TDocument) {
  const extra = {
    ...defaultExtra,
    method: "DELETE"
  };

  const resp = await fetch(`/api/document/one/${doc.id}`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't delete document");
  }
}

export async function login(username: string, password: string) {
  const extra = {
    ...defaultExtra,
    body: JSON.stringify({
      username,
      password
    }),
    method: "POST"
  };

  const resp = await fetch(`/api/auth/login`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't log in");
  }
}

export async function fetchuser() {
  const extra = { ...defaultExtra };
  const resp = await fetch(`/api/user/`, extra);
  const orig = await resp.json();
  const user = T.User(orig)
    .update("profile", p => T.Profile(JSON.parse(p as any)))
    .updateIn(["profile", "savedSearches"], s => Im.Set(s));

  return user;
}

export async function patchUser(userData: T.TUser) {
  const extra = {
    ...defaultExtra,
    body: JSON.stringify(userData.profile.toJS()),
    method: "POST"
  };
  const resp = await fetch(`/api/user/`, extra);
  if (!resp.ok) {
    throw new Error("Couldn't update user profile");
  }
  return null;
}

export async function uploadImage(inputFiles: any[]) {
  const formData = new FormData();

  const files = Array.from(inputFiles);
  files.forEach((file: Blob, i) => {
    formData.append("file", file);
  });

  const auth_jwt = getCookieValue("AUTH");
  const api_url = `https://europe-west3-anynote-app.cloudfunctions.net/image-upload?jwt_token=${auth_jwt}`;

  // `http://localhost:5000?jwt_token=${auth_jwt}`,
  return fetch(api_url, {
    method: "POST",
    body: formData
  }).then(res => res.json());
}
