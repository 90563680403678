import { SchemaProperties } from "slate";

export enum BlockType {
  // Atoms
  Video = "video",
  Image = "image",
  Code = "code",
  // Iframe = "iframe",

  // Text
  Paragraph = "paragraph",
  ListUnordered = "list/unordered",
  ListOrdered = "list/ordered",
  ListItem = "list/item"
}

export enum InlineType {
  Link = "link",
  // Math = "math",
  Code = "code",
  Tag = "simpletag"
}

export enum MarkType {
  Bold = "bold",
  Italic = "italic",
  Underlined = "underlined"
}

// export const atomTypes = [BlockType.Video, BlockType.Image, BlockType.Iframe];

export const textAtomTypes = [
  BlockType.Paragraph,
  BlockType.Code,
  BlockType.ListUnordered,
  BlockType.ListOrdered
];

export const listTypes = [
  BlockType.ListUnordered,
  BlockType.ListOrdered,
  BlockType.ListItem
];

// const normalize = (editor: Editor, error: any) => {
//   console.error("normalization error", editor, error);
//   // TODO: send to monitoring backend
// };

export const schema = {
  document: {
    nodes: [
      {
        match: [
          { type: BlockType.Paragraph },
          { type: BlockType.Code },
          { type: BlockType.Image },
          { type: BlockType.Video },
          { type: BlockType.ListOrdered },
          { type: BlockType.ListUnordered }
        ]
      }
    ]
    // normalize
  },
  blocks: {
    [BlockType.Video]: {
      data: { url: c => typeof c === "string" },
      isVoid: true
    },
    [BlockType.Image]: {
      data: { url: c => typeof c === "string" },
      isVoid: true
    },
    // [BlockType.Code]: {
    //   data: {
    //     // language: l => typeof l === "string"
    //   },
    //   nodes: [{ match: { object: "text" } }],
    //   normalize
    // },
    [BlockType.Paragraph]: {
      data: {
        // fontSize: s => typeof s === "number",
        // textAlign: s =>
        //   typeof ["left", "right", "justify", "center"].includes(s)
      },
      match: [
        { object: "text" },
        { type: InlineType.Tag },
        { type: InlineType.Link }
      ]
    },
    [BlockType.Code]: {
      match: [{ object: "text" }]
    },
    [BlockType.ListUnordered]: {
      match: [{ type: BlockType.ListItem }]
      // normalize
    },
    [BlockType.ListOrdered]: {
      match: [{ type: BlockType.ListItem }]
      // normalize
    },
    [BlockType.ListItem]: {
      nodes: [
        {
          max: 2,
          min: 0,
          match: [
            { type: BlockType.ListUnordered },
            { type: BlockType.ListOrdered },
            { type: BlockType.Paragraph }
          ]
        }
      ]
      // normalize
    }
  },
  inlines: {
    [InlineType.Link]: {
      data: {
        url: c => typeof c === "string"
      },
      nodes: [{ match: { object: "text" } }]
    },
    // [InlineType.Math]: {
    //   data: {
    //     //  formula: c => typeof c === "string"
    //   },
    //   isVoid: true,
    //   normalize
    // },
    [InlineType.Tag]: {
      data: {
        text: t => typeof t === "string"
      },
      isVoid: true
      // normalize
    },
    [InlineType.Code]: {
      data: {}
    }
  }
} as SchemaProperties;
