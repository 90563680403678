import * as React from "react";
import * as T from "types";
import { Editor as SlateEditor, OnChangeParam } from "slate-react";
import { Map, Set } from "immutable";
import { ParagraphPlugin } from "./Paragraph";
import { InlineType, schema, BlockType } from "./schema";
import { TagPlugin } from "./Tag";
import { ListPlugin } from "./List";
import { LinkPlugin } from "./Link";
import { ImagePlugin } from "./Image";
import { VideoPlugin } from "./Video";
import { CodePlugin } from "./Code";

const plugins = [
  ParagraphPlugin,
  TagPlugin,
  CodePlugin,
  ListPlugin,
  VideoPlugin,
  ImagePlugin,
  LinkPlugin
];

interface Props {
  doc: T.TDocument;
  onDocUpdate: (d: T.TDocument) => void;
  setSearchQuery: (query: string) => void;
  editorRef: React.MutableRefObject<any>;
}

const Sl = SlateEditor as any;

const Editor: React.SFC<Props> = p => {
  const onChange = (change: OnChangeParam) => {
    let metadata = Map<string, Set<string>>();

    // Scan for simpleTags
    change.value.document
      .getInlines()
      .filter(i => i && i != null && i.type === InlineType.Tag)
      .forEach(i => {
        const key = "tags";
        const val = i.data.get("text");
        if (!key || !val) {
          return;
        }

        metadata = metadata.update(key, list => {
          if (!list) {
            list = Set();
          }
          return list.add(val);
        });
      });

    p.onDocUpdate(p.doc.set("metadata", metadata).set("body", change.value));
  };

  return (
    <div>
      <Sl
        setSearchQuery={p.setSearchQuery}
        spellCheck={false}
        plugins={plugins}
        schema={schema}
        value={p.doc.body}
        onChange={onChange}
        ref={p.editorRef}
      />
      {/* <hr />
      <pre>{JSON.stringify(p.doc.body.toJS(), undefined, 2)}</pre> */}
    </div>
  );
};

export default Editor;
