// Generated from QueryLang.gr by ANTLR 4.7.3-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class QueryLangLexer extends Lexer {
	public static readonly AND = 1;
	public static readonly OR = 2;
	public static readonly NOT = 3;
	public static readonly LPAREN = 4;
	public static readonly RPAREN = 5;
	public static readonly TAG = 6;
	public static readonly WS = 7;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"AND", "OR", "NOT", "LPAREN", "RPAREN", "TAG", "WS",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'and'", "'or'", "'not'", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "AND", "OR", "NOT", "LPAREN", "RPAREN", "TAG", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(QueryLangLexer._LITERAL_NAMES, QueryLangLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return QueryLangLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(QueryLangLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "QueryLang.gr"; }

	// @Override
	public get ruleNames(): string[] { return QueryLangLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return QueryLangLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return QueryLangLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return QueryLangLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\t,\b\x01\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x03\x02\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03" +
		"\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03" +
		"\x07\x06\x07\"\n\x07\r\x07\x0E\x07#\x03\b\x06\b\'\n\b\r\b\x0E\b(\x03\b" +
		"\x03\b\x02\x02\x02\t\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02" +
		"\x07\r\x02\b\x0F\x02\t\x03\x02\x04\x06\x022;C\\aac|\x05\x02\v\f\x0E\x0F" +
		"\"\"\x02-\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03" +
		"\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02" +
		"\x02\x02\x02\x0F\x03\x02\x02\x02\x03\x11\x03\x02\x02\x02\x05\x15\x03\x02" +
		"\x02\x02\x07\x18\x03\x02\x02\x02\t\x1C\x03\x02\x02\x02\v\x1E\x03\x02\x02" +
		"\x02\r!\x03\x02\x02\x02\x0F&\x03\x02\x02\x02\x11\x12\x07c\x02\x02\x12" +
		"\x13\x07p\x02\x02\x13\x14\x07f\x02\x02\x14\x04\x03\x02\x02\x02\x15\x16" +
		"\x07q\x02\x02\x16\x17\x07t\x02\x02\x17\x06\x03\x02\x02\x02\x18\x19\x07" +
		"p\x02\x02\x19\x1A\x07q\x02\x02\x1A\x1B\x07v\x02\x02\x1B\b\x03\x02\x02" +
		"\x02\x1C\x1D\x07*\x02\x02\x1D\n\x03\x02\x02\x02\x1E\x1F\x07+\x02\x02\x1F" +
		"\f\x03\x02\x02\x02 \"\t\x02\x02\x02! \x03\x02\x02\x02\"#\x03\x02\x02\x02" +
		"#!\x03\x02\x02\x02#$\x03\x02\x02\x02$\x0E\x03\x02\x02\x02%\'\t\x03\x02" +
		"\x02&%\x03\x02\x02\x02\'(\x03\x02\x02\x02(&\x03\x02\x02\x02()\x03\x02" +
		"\x02\x02)*\x03\x02\x02\x02*+\b\b\x02\x02+\x10\x03\x02\x02\x02\x05\x02" +
		"#(\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!QueryLangLexer.__ATN) {
			QueryLangLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(QueryLangLexer._serializedATN));
		}

		return QueryLangLexer.__ATN;
	}

}

