import * as React from "react";
import * as Ui from "semantic-ui-react";
import {
  Editor,
  Plugin,
  getEventTransfer,
  RenderBlockProps
} from "slate-react";
import { BlockType } from "../schema";
import { css } from "emotion";

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case BlockType.Video: {
      const url = p.node.data.get("url");
      return (
        <div
          {...p.attributes}
          className={css`
            width: 50%;
            margin: auto;
          `}
        >
          <Ui.Embed url={url} defaultActive />
        </div>
      );
    }

    default: {
      return next();
    }
  }
};

const isVideo = (raw: string) => {
  try {
    const url = new URL(raw);
    return url.host.endsWith("youtube.com");
  } catch (error) {
    console.log(error);
    return false;
  }
};

const onPaste = (event: React.ClipboardEvent, editor: Editor, next: any) => {
  const transfer = getEventTransfer(event) as any;
  const { type, text } = transfer;
  if (type !== "text" && type !== "html") return next();
  if (!isVideo(text)) return next();
  event.preventDefault();

  const oldURL = new URL(text);
  const url = `https://youtube.com/embed/${oldURL.searchParams.get("v")}`;

  editor.insertBlock({
    type: BlockType.Video,
    data: {
      url
    }
  });
};

export const VideoPlugin = {
  renderBlock,
  onPaste
} as Plugin;
