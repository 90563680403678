import * as React from "react";
import * as Ui from "semantic-ui-react";
import styled from "@emotion/styled";

interface Props {
  trigger?: React.SFC<any>;
  onPositive: () => void;
  description: React.ReactNode;
}

export const PositiveNegativeModal: React.SFC<Props> = p => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <Ui.Modal
      trigger={<p.trigger onClick={open} />}
      basic
      size="small"
      open={isOpen}
      onClose={close}
    >
      {p.description}
      <Ui.Modal.Actions>
        <Ui.Button color="red" inverted onClick={close}>
          <Ui.Icon name="remove" /> No
        </Ui.Button>
        <Ui.Button color="green" inverted onClick={p.onPositive}>
          <Ui.Icon name="checkmark" /> Yes
        </Ui.Button>
      </Ui.Modal.Actions>
    </Ui.Modal>
  );
};

export const AppOuter = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  min-height: 100vh;
`;

export const AppInner = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 1681px) {
    /* tablets and desktop */
    width: 50%;
  }

  @media only screen and (max-width: 1680px) {
    /* tablets and desktop */
    width: 65%;
  }

  @media only screen and (max-width: 1024px) {
    /* phones */
    width: 82%;
  }

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    /* portrait phones */
    width: 100%;
  }

  padding: 1.3em;
  padding-top: 7em;
  border-radius: 4px;
  background-color: #f6f6f6;
`;
