import { Map } from "immutable";
import { isKeyHotkey } from "is-hotkey";
import { Editor } from "slate-react";
import * as U from "./utils";
import { BlockType } from "../schema";
import * as C from "./changes";

const onTab = (e: KeyboardEvent, editor: Editor, next: any) => {
  if (!editor.value.selection.isCollapsed) {
    return next();
  }

  e.preventDefault();
  C.increaseItemDepth(editor);
  return true;
};

const onSpace = (e: KeyboardEvent, editor: Editor, next: any) => {
  const currentItem = U.getCurrentItem(editor.value);
  if (
    !(
      editor.value.selection.isCollapsed &&
      editor.value.selection.anchor.isAtStartOfNode(currentItem)
    )
  ) {
    return next();
  }

  e.preventDefault();
  C.increaseItemDepth(editor);
  return true;
};

const onShiftTab = (e: KeyboardEvent, editor: Editor, next: any) => {
  if (!editor.value.selection.isCollapsed) {
    return next();
  }
  e.preventDefault();
  C.decreaseItemDepth(editor);
  return true;
};

const onEnter = (e: KeyboardEvent, editor: Editor, next: any) => {
  const currentItem = U.getCurrentItem(editor.value);
  e.preventDefault();
  if (editor.value.selection.isExpanded) {
    editor.delete();
  }

  if (currentItem.text === "") {
    if (U.getItemDepth(editor.value) > 1) {
      C.decreaseItemDepth(editor);
      return true;
    }
    C.unWrapList(editor);
    return true;
  }
  C.splitListItem(editor);
  return true;
};

const onBackspace = (e: KeyboardEvent, editor: Editor, next: any) => {
  if (
    editor.value.selection.isExpanded ||
    editor.value.selection.start.offset > 0 ||
    U.getItemDepth(editor.value) <= 1
  ) {
    return next();
  }
  e.preventDefault();
  C.decreaseItemDepth(editor);
  return true;
};

const onSpaceListInit = (e: KeyboardEvent, editor: Editor, next: any) => {
  if (!editor.value.selection.isCollapsed) {
    return;
  }
  if (editor.value.startBlock.type !== BlockType.Paragraph) {
    return;
  }
  if (editor.value.selection.start.offset == 1) {
    if (
      !(
        editor.value.startText.text.startsWith("-") ||
        editor.value.startText.text.startsWith("*")
      )
    ) {
      return;
    }

    editor.deleteBackward(1);
    C.wrapInList(editor, BlockType.ListUnordered);
    return next();
  } else if (editor.value.selection.start.offset == 2) {
    if (
      !(
        editor.value.startText.text.startsWith("1.") ||
        editor.value.startText.text.startsWith("1)")
      )
    ) {
      return;
    }

    editor.deleteBackward(2);
    C.wrapInList(editor, BlockType.ListOrdered);
    return next();
  }
};

const hotKeys = Map({
  tab: onTab,
  backspace: onBackspace,
  space: onSpace,
  enter: onEnter,
  "shift+tab": onShiftTab
});

const boundHotKeys = hotKeys.mapKeys(k => isKeyHotkey(k));

const preHotkeys = Map({
  space: onSpaceListInit
});

const boundPreHotKeys = preHotkeys.mapKeys(k => isKeyHotkey(k));

export const onKeyDown = (e: any, editor: Editor, next: any) => {
  const f2 = boundPreHotKeys.find((v, k) => k(e));
  if (f2) {
    const res = f2(e, editor, next);
    if (res) {
      return res;
    }
  }

  if (!U.getCurrentItem(editor.value)) {
    return next();
  }
  const f = boundHotKeys.find((v, k) => k(e));
  if (f) {
    return f(e, editor, next);
  } else {
    next();
  }
};
