import { ANTLRInputStream, CommonTokenStream } from "antlr4ts";
import { QueryLangLexer } from "./grammar/QueryLangLexer";
import * as Parser from "./grammar/QueryLangParser";

export const text2ParseTree = (query: string) => {
  let inputStream = new ANTLRInputStream(query);
  let lexer = new QueryLangLexer(inputStream);
  let tokenStream = new CommonTokenStream(lexer);
  let parser = new Parser.QueryLangParser(tokenStream);

  return parser.query();
};
