import * as React from "react";
import * as Ui from "semantic-ui-react";
import Document from "containers/Document";
import { testQuery } from "Query/queryEngine";
import { text2Query } from "Query";
import * as LocComps from "./Components";
import InfiniteScroll from "react-infinite-scroll-component";
import { DocStoreState } from "containers/docStore";
import SavedSearches from "containers/TagViz/SavedSearches";

interface Props {
  docState: DocStoreState;
  rawSearch: string;
  setRawSearch: (s: string) => void;
}

const Collection: React.SFC<Props> = p => {
  const [renderIndex, setRenderIndex] = React.useState(20);
  const [searchTime, setSearchTime] = React.useState(0);

  const filterDocs = React.useMemo(() => {
    const startTime = performance.now();
    const query = text2Query(p.rawSearch);
    const res = p.docState.meta
      .filter(d => testQuery(query, d.metadata.toJS()))
      .toList()
      .sortBy(d => d.created)
      .reverse();
    const endTime = performance.now();
    const searchTime = endTime - startTime;
    console.log("searching", searchTime);
    setSearchTime(searchTime);

    return res;
  }, [p.rawSearch, p.docState.meta]);

  const renderDocs = React.useMemo(() => filterDocs.slice(0, renderIndex), [
    filterDocs,
    renderIndex
  ]);

  const renderMore = () => setRenderIndex(renderIndex + 10);
  return (
    <div>
      {!p.rawSearch && <SavedSearches {...p} />}
      {p.rawSearch && (
        <p>
          Found {filterDocs.size} in {Math.round(searchTime / 1) / 1000}s
        </p>
      )}
      <InfiniteScroll
        dataLength={renderDocs.size}
        next={renderMore}
        hasMore={renderDocs.size != filterDocs.size}
        loader={<Ui.Loader />}
        refreshFunction={() => (window.location as any).reload()}
        pullDownToRefresh
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        pullDownToRefreshThreshold={100}
      >
        {renderDocs.map(metaDoc => {
          const doc = p.docState.docs.get(metaDoc.id);
          return (
            <div key={doc.id}>
              <LocComps.NoteContainer>
                <Document doc={doc} setSearchQuery={p.setRawSearch} />
              </LocComps.NoteContainer>
              <LocComps.Separator />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default Collection;
