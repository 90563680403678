import * as React from "react";
import { DocStoreState } from "containers/docStore";
import { List, Set } from "immutable";
import { Link } from "react-router-dom";
import { css } from "emotion";

interface Props {
  docState: DocStoreState;
  rawSearch: string;
  setRawSearch: (s: string) => void;
}

const Alphabetical: React.SFC<Props> = p => {
  const tags = p.docState.meta.reduce((tags, doc) => {
    doc.metadata.get("tags", List()).forEach(t => {
      tags = tags.add(t);
    });
    return tags;
  }, Set<string>());

  const groups = tags.groupBy(t => t[0]);

  return (
    <div>
      <h2>All tags</h2>
      <hr />

      <div
        className={css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        {groups
          .map((tags, initial) => (
            <div
              className={css`
                background-color: white;
                margin: 10px;
                padding: 1em;
                font-size: 1.2em;

                @media only screen and (min-width: 1024px) {
                  /* tablets and desktop */
                  width: 22%;
                }

                @media only screen and (max-width: 1023px) {
                  /* phones */
                  width: 30%;
                }

                @media only screen and (max-width: 767px) and (orientation: portrait) {
                  /* portrait phones */
                  padding: 1.5em;
                  width: 100%;
                }
              `}
            >
              <strong>{initial.toUpperCase()}</strong>
              {tags.map(tag => (
                <li onClick={() => p.setRawSearch(tag)}>
                  <Link to="/">{tag}</Link>
                </li>
              ))}
            </div>
          ))
          .toList()}
      </div>
    </div>
  );
};

export default Alphabetical;
