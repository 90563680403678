import * as React from "react";
import {
  RenderInlineProps,
  Editor,
  Plugin,
  RenderBlockProps
} from "slate-react";
import { Map } from "immutable";
import { InlineType, BlockType } from "../schema";
import { isKeyHotkey } from "is-hotkey";
import { css } from "emotion";
import { Text, Inline, Block, Range, Point } from "slate";

const blockRegex = / ```(.*)```/gi;
const inlineRegex = / `([^`]+)`/gi;

const onChange = (ed: Editor, next: any) => {
  const thisBlock = ed.value.startBlock;
  if (!thisBlock) {
    return;
  }

  const inlineRes = inlineRegex.exec(thisBlock.text);
  if (inlineRes) {
    const start = inlineRes.index;
    const end = start + inlineRes[0].length;
    const content = inlineRes[1];
    const newInline = Inline.create({
      type: InlineType.Code,
      nodes: [Text.create({ text: content })]
    });

    const range = Range.create({
      anchor: Point.create({
        key: thisBlock.key,
        // path: ed.value.document.getPath(thisBlock.texts).toArray(),
        // path: [0, 0],
        // path: thisBlock.getPath(thisBlock).toArray(),
        offset: start
      }),
      focus: Point.create({
        key: thisBlock.key,
        // path: ed.value.document.getPath(thisBlock.texts[0]).toArray(),
        // path: [0, 0],
        // path: thisBlock.getPath(thisBlock).toArray(),
        offset: end
      })
    });
    ed.select(range);
    ed.insertInline(newInline);
    // ed.deleteAtRange(range);
    // ed.insertInlineAtRange(range, newInline);
    return;
  }

  const blockRes = blockRegex.exec(thisBlock.text);
  if (blockRes) {
    const start = blockRes.index;
    const end = start + blockRes[0].length;
    const content = blockRes[1];
    const newBlock = Block.create({
      type: BlockType.Code,
      nodes: [Text.create({ text: content })]
    });
    const range = Range.create({
      anchor: Point.create({
        key: thisBlock.key,
        // path: ed.value.document.getPath(thisBlock.texts).toArray(),
        // path: [0, 0],
        // path: thisBlock.getPath(thisBlock).toArray(),
        offset: start
      }),
      focus: Point.create({
        key: thisBlock.key,
        // path: ed.value.document.getPath(thisBlock.texts[0]).toArray(),
        // path: [0, 0],
        // path: thisBlock.getPath(thisBlock).toArray(),
        offset: end
      })
    });

    ed.select(range);
    ed.insertBlock(newBlock);

    return;
  }

  return next();
};

// const onBacktick = (e: KeyboardEvent, editor: Editor, next: any) => {
//   // if (!editor.value.selection.isCollapsed) {
//   //   return next();
//   // }

//   const offset = editor.value.selection.start.offset;
//   const text = editor.value.startText;
//   const preceding = text.text.slice(offset - 2, offset);
//   console.log("ofte", offset, text, "lala", preceding);

//   if (preceding == "``") {
//     console.log("itscodeblock");
//     return next();
//   } else if (preceding[preceding.length - 1] == "`") {
//     e.preventDefault();
//     console.log("itscodeinline");
//     editor.deleteBackward(1);
//     editor.insertInline({
//       type: InlineType.Code,
//       nodes: [{ object: "text", text: " " }]
//     });
//     editor.moveBackward();
//     return;
//   }

//   return next();

//   //   if (editor.value.selection.start.offset !== 1) {
//   //     return next();
//   //   }
//   //   if (editor.value.startText.text !== "-") {
//   //     return next();
//   //   }
//   //   if (editor.value.startBlock.type != BlockType.Paragraph) {
//   //     return next();
//   //   }

//   //   C.wrapInList(editor, BlockType.ListUnordered);
//   //   return next();
// };

const onEnter = (e: KeyboardEvent, editor: Editor, next: any) => {
  if (editor.value.startBlock.type !== BlockType.Code) {
    return next();
  }

  e.preventDefault();
  editor.insertText("\n");
};
const hotKeys = Map({
  // "`": onBacktick
  enter: onEnter
});

const boundHotKeys = hotKeys.mapKeys(k => isKeyHotkey(k));

const onKeyDown = (e: any, editor: Editor, next: any) => {
  const f = boundHotKeys.find((v, k) => k(e));
  if (f) {
    return f(e, editor, next);
  } else {
    next();
  }
};

const renderInline = (p: RenderInlineProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case InlineType.Code: {
      return (
        <span
          {...p.attributes}
          className={css`
            background-color: lightgray;
            padding: 0px 0.2em;
            font-family: monospace;
          `}
        >
          {p.children}
        </span>
      );
    }

    default: {
      return next();
    }
  }
};

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case BlockType.Code: {
      return (
        <div
          {...p.attributes}
          className={css`
            background-color: lightgray;
            font-family: monospace;
            background: #f4f4f4;
            page-break-inside: avoid;
            padding-left: 1em;
            line-height: 1;
            max-width: 100%;
            overflow: auto;
            word-wrap: break-word;
          `}
        >
          <pre>{p.children}</pre>
        </div>
      );
    }

    default: {
      return next();
    }
  }
};

export const CodePlugin = {
  renderBlock,
  renderInline,
  onChange,
  onKeyDown
  // onPaste: TODO: avoid creating multiple blocks
} as Plugin;
