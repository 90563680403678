import * as React from "react";
import * as Ui from "semantic-ui-react";
import { text2Query } from "Query";
import { query2NecessaryTags } from "Query/query2necessaryTags";
import { useAfterQuiet } from "containers/customHooks";
import { DocStoreDispatchCtx } from "containers/docStore";
import { Link, Redirect } from "react-router-dom";
import { UserStoreDispatchCtx } from "containers/userStore";

interface Props {
  searchQuery: string;
  setSearchQuery: (s: string) => void;
}

const NavBar: React.SFC<Props> = p => {
  const actions = React.useContext(DocStoreDispatchCtx);
  const [internalQ, setInternalQ] = React.useState(p.searchQuery);
  const userCtx = React.useContext(UserStoreDispatchCtx);

  React.useEffect(() => setInternalQ(p.searchQuery), [p.searchQuery]);

  const onSearch = (e: any) => {
    setInternalQ(e.target.value);
  };

  useAfterQuiet(
    setNotDirty => {
      p.setSearchQuery(internalQ);
      setNotDirty();
    },
    [internalQ],
    400
  );

  const makeNew = () => {
    const query = text2Query(p.searchQuery);
    const tags = query2NecessaryTags(query);
    actions.createDoc(tags);
  };

  const isPageHome = window.location.pathname == "/";
  const isPageTagViz = window.location.pathname == "/tags";
  const [isHomeActive, setIsHomeActive] = React.useState(false);
  const HomeRedirector = () => {
    if (!isHomeActive) {
      return null;
    }
    setIsHomeActive(false);
    const path = window.location.pathname;
    console.log("home", p.searchQuery, path);
    if (p.searchQuery != "") {
      p.setSearchQuery("");
      return <Redirect to="/" />;
    } else if (isPageHome) {
      console.log(2);
      return <Redirect to="/tags" />;
    } else if (isPageTagViz) {
      return <Redirect to="/" />;
    }
  };

  const isSearchSaved = userCtx.state.profile.savedSearches.contains(internalQ);
  const toggleSaveSearch = () => {
    if (isSearchSaved) {
      userCtx.savedSearchRm(internalQ);
    } else {
      userCtx.savedSearchAdd(internalQ);
    }
  };

  return (
    <Ui.Menu fixed="top">
      <Ui.Menu.Item>
        <Ui.Icon name="home" onClick={() => setIsHomeActive(true)} />
        <HomeRedirector />
      </Ui.Menu.Item>
      {isPageHome && (
        <Ui.Menu.Item>
          <Ui.Icon name="plus" onClick={makeNew} />
        </Ui.Menu.Item>
      )}
      <span style={{ width: "100%", fontSize: "16px" }}>
        <Ui.Menu.Item style={{ width: "100%" }}>
          <Ui.Input
            icon="search"
            placeholder="Search..."
            value={internalQ}
            onChange={onSearch}
          />
        </Ui.Menu.Item>
      </span>
      <Ui.Menu.Item>
        <Ui.Icon
          name="favorite"
          color={isSearchSaved ? "red" : "black"}
          onClick={toggleSaveSearch}
        />
      </Ui.Menu.Item>
    </Ui.Menu>
  );
};

export default NavBar;
