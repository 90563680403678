import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Global, css } from "@emotion/core";
import "semantic-ui-css/semantic.min.css";
import "./Query/queryEngine";
import { Login } from "containers/Login";
import * as LocComps from "./containers/Components";

import Collection from "containers/Collection";
import { useDocStore } from "containers/docStore";
import NavBar from "containers/NavBar";
import { useStateInQueryString } from "containers/customHooks";
import TagViz from "containers/TagViz";
import { useUserStore } from "containers/userStore";

const globalStyles = css`
  @import url("https://fonts.googleapis.com/css?family=Quicksand:400,500&display=swap");
`;

const App: React.FC = () => {
  const docStore = useDocStore();
  const userStore = useUserStore();

  // Load user data
  React.useEffect(() => {
    userStore.actions.loadProfile();
  }, []);

  // Load metadata
  React.useEffect(() => {
    docStore.actions.loadMetadata();
  }, []);

  const [rawSearch, setRawSearch] = useStateInQueryString("q", "");

  return (
    <div>
      <Global styles={globalStyles} />
      <userStore.Context>
        <docStore.Context>
          <LocComps.AppOuter>
            <LocComps.AppInner>
              <Router>
                <NavBar searchQuery={rawSearch} setSearchQuery={setRawSearch} />
                <Switch>
                  <Route component={Login} path="/login" />
                  <Route path="/tags">
                    <TagViz
                      rawSearch={rawSearch}
                      setRawSearch={setRawSearch}
                      docState={docStore.state}
                    />
                  </Route>
                  <Route path="/:searchQuery?">
                    <Collection
                      rawSearch={rawSearch}
                      setRawSearch={setRawSearch}
                      docState={docStore.state}
                    />
                  </Route>
                </Switch>
              </Router>
              {// docStore.state.docs.isEmpty() &&
              userStore.state.username ? (
                <p>Hi, {userStore.state.username}</p>
              ) : (
                <a href="/login">Login</a>
              )}
            </LocComps.AppInner>
          </LocComps.AppOuter>
        </docStore.Context>
      </userStore.Context>
    </div>
  );
};

export default App;
