import * as React from "react";
import * as T from "types";
import * as Api from "api";
import { Map } from "immutable";
import { makeid } from "utils/misc";

export interface DocStoreState {
  docs: T.TDocumentMap;
  meta: T.TDocumentMap;
}

const initState = {
  docs: Map() as T.TDocumentMap,
  meta: Map() as T.TDocumentMap
};

type Action =
  | { type: "set_all"; docs: T.TDocumentCollection }
  | { type: "set_some"; docs: T.TDocumentCollection }
  | { type: "substitute_id"; old: string; new: string }
  | { type: "set_one"; doc: T.TDocument }
  | { type: "add_one"; doc: T.TDocument }
  | { type: "delete_one"; doc: T.TDocument };

const reducer = (state: DocStoreState, action: Action): DocStoreState => {
  switch (action.type) {
    case "set_all":
      const docs = action.docs.reduce(
        (m, d) => m.set(d.id, d),
        Map()
      ) as T.TDocumentMap;

      return {
        docs,
        meta: docs.map(d => d.set("body", null))
      };
    case "set_one": {
      const isSameMeta =
        action.doc.metadata.hashCode() ==
        state.docs.get(action.doc.id).metadata.hashCode();

      const meta = isSameMeta
        ? state.meta
        : state.meta.set(action.doc.id, action.doc.set("body", null));

      return {
        docs: state.docs.set(action.doc.id, action.doc),
        meta
      };
    }
    case "delete_one": {
      return {
        meta: state.meta.delete(action.doc.id),
        docs: state.docs.delete(action.doc.id)
      };
    }
    case "add_one": {
      return {
        meta: state.meta.set(action.doc.id, action.doc.set("body", null)),
        docs: state.meta.set(action.doc.id, action.doc)
      };
    }
    case "set_some": {
      let res = state;
      action.docs.forEach(newD => {
        res = reducer(res, { type: "set_one", doc: newD });
      });

      return res;
    }
    case "substitute_id": {
      const doc = state.docs.get(action.old).set("id", action.new);
      return {
        meta: state.meta
          .delete(action.old)
          .set(action.new, doc.set("body", null)),
        docs: state.meta.delete(action.old).set(action.new, doc)
      };
    }
    default:
      throw new Error("unknown action: ${action}");
  }
};

const actionCreator = (dispatch: React.Dispatch<Action>) => ({
  dispatch,
  setDocs: (docs: T.TDocumentCollection) => dispatch({ type: "set_all", docs }),
  setDocsSome: (docs: T.TDocumentCollection) =>
    dispatch({ type: "set_some", docs }),
  setDoc: (doc: T.TDocument) => dispatch({ type: "set_one", doc }),
  loadMetadata: () => {
    Api.fetchMeta().then(docs => dispatch({ type: "set_all", docs }));
  },
  loadDocBody: (doc: T.TDocument) =>
    (async function a() {
      if (doc.body) {
        return;
      }
      const loadedDoc = await Api.fetchDoc(doc.id);
      dispatch({ type: "set_one", doc: loadedDoc });
    })(),
  deleteDoc: (doc: T.TDocument) => {
    dispatch({ doc, type: "delete_one" });
    Api.deleteDoc(doc).catch(actionCreator(dispatch).loadMetadata);
  },
  createDoc: (tags: string[]) => {
    const value = T.initValue(tags);
    const newDoc = T.Document({
      body: value as any,
      metadata: Map({ tags }),
      id: makeid(10) // this is a temporary local ID
    });

    dispatch({ type: "add_one", doc: newDoc });

    Api.createDoc(newDoc)
      .then(newId =>
        dispatch({ type: "substitute_id", old: newDoc.id, new: newId })
      )
      .catch(actionCreator(dispatch).loadMetadata);
  }
});

export const DocStoreDispatchCtx = React.createContext<
  ReturnType<typeof actionCreator>
>(null);

export const useDocStore = () => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const actions = React.useMemo(() => actionCreator(dispatch), []);

  const Context = React.useCallback(
    (p: any) => (
      <DocStoreDispatchCtx.Provider value={actions}>
        {p.children}
      </DocStoreDispatchCtx.Provider>
    ),
    []
  );

  return { state, Context, actions };
};
