import { List } from "immutable";
import { Block, Value } from "slate";
import { BlockType } from "../schema";

export const isList = (b: Block) =>
  b.type === BlockType.ListOrdered || b.type === BlockType.ListUnordered;

export const getCurrentItem = (v: Value, b?: Block): Block => {
  b = b || v.startBlock;
  if (!b) {
    return null;
  }

  const parent = v.document.getParent(b.key) as Block;
  return parent.type === BlockType.ListItem && parent;
};

export const getItemDepth = (v: Value, b?: Block): number => {
  const current = getCurrentItem(v, b);
  if (!current) {
    return 0;
  }

  const parent = v.document.getParent(current.key) as Block;
  return 1 + getItemDepth(v, parent);
};

export const getListForItem = (v: Value, b: Block): Block => {
  const parent = v.document.getParent(b.key) as Block;
  return isList(parent) && parent;
};

export const getCurrentList = (v: Value, b?: Block): Block => {
  const item = getCurrentItem(v, b);
  return item && getListForItem(v, item);
};

export const getPreviousItem = (v: Value, b?: Block): Block => {
  const item = getCurrentItem(v, b);
  if (!item) {
    return null;
  }

  const prevSibling = v.document.getPreviousSibling(item.key) as Block;
  return prevSibling && prevSibling.type === BlockType.ListItem && prevSibling;
};

export const getItemsAtRange = (v: Value) => {
  const r = v.selection;

  if (!r.start.key) {
    return List();
  }

  const startB = v.document.getClosestBlock(r.start.key);
  const endB = v.document.getClosestBlock(r.end.key);

  if (startB === endB) {
    const item = getCurrentItem(v, startB);
    return item ? List([item]) : List();
  }

  const ancestor = (v.document as any).getCommonAncestor(
    startB.key,
    endB.key
  ) as Block;

  if (isList(ancestor)) {
    const startPath = (ancestor as any).getPath(startB.key);
    const endPath = (ancestor as any).getPath(endB.key);

    return ancestor.nodes.slice(startPath[0], endPath[0] + 1);
  } else if (ancestor.type === BlockType.ListItem) {
    return List([ancestor]);
  }

  return List();
};

export const isSelectionInList = (v: Value) => !getItemsAtRange(v).isEmpty();
