import * as Ui from "semantic-ui-react";
import styled from "@emotion/styled";

export const Separator = styled.div`
  width: 100%;
  height: 0.15em;
  display: none;
  background-color: rgb(61, 186, 117, 0.7);
  background-color: #4f90c4;
  // margin: 1em 0px;
  border-radius: 5px;
`;

export const NoteContainer = styled.div`
  font-size: 1.15em;
  background-color: #fff;
  padding: 0.7em;
  border-radius: 3px;
  margin-bottom: 1em;
  background-color: #f6f6f6;
  background-color: #fff;
`;
