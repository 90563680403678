import * as React from "react";
import * as T from "types";
import * as Api from "api";

export type UserStoreState = T.TUser;

const initState = T.User();

type Action =
  | { type: "set_profile"; profile: T.TProfile }
  | { type: "set_user"; user: T.TUser }
  | { type: "saved_search_add"; query: string }
  | { type: "saved_search_rm"; query: string };

const reducer = (state: UserStoreState, action: Action): UserStoreState => {
  switch (action.type) {
    case "set_user":
      return action.user;
    case "set_profile":
      return state.set("profile", action.profile);
    case "saved_search_add": {
      const s = state.updateIn(["profile", "savedSearches"], l =>
        l.add(action.query)
      );
      Api.patchUser(s);

      return s;
    }
    case "saved_search_rm": {
      const s = state.updateIn(["profile", "savedSearches"], l =>
        l.remove(action.query)
      );
      Api.patchUser(s);
      return s;
    }
    default:
      throw new Error("unknown action: ${action}");
  }
};

const actionCreator = (dispatch: React.Dispatch<Action>) => ({
  dispatch,
  savedSearchAdd: (query: string) => {
    dispatch({ type: "saved_search_add", query: query });
  },
  savedSearchRm: (query: string) => {
    dispatch({ type: "saved_search_rm", query: query });
  },

  // setProfile: (profile: T.TProfile) =>
  //   dispatch({ type: "set_profile", profile }),
  loadProfile: () => {
    Api.fetchuser().then(user => dispatch({ type: "set_user", user }));
  }
});

interface Ctx extends ReturnType<typeof actionCreator> {
  state: T.TUser;
}

export const UserStoreDispatchCtx = React.createContext<Ctx>(null);

export const useUserStore = () => {
  const [state, dispatch] = React.useReducer(reducer, initState);
  const actions = React.useMemo(() => ({ ...actionCreator(dispatch), state }), [
    state
  ]);

  const Context = React.useCallback(
    (p: any) => (
      <UserStoreDispatchCtx.Provider value={actions}>
        {p.children}
      </UserStoreDispatchCtx.Provider>
    ),
    [state]
  );

  return { state, Context, actions };
};
