import * as Parser from "./grammar/QueryLangParser";
import { ParseTree } from "antlr4ts/tree/ParseTree";
import * as QEngine from "Query/queryEngine";

const parseTree2QueryInner = (parseTree: ParseTree) => {
  // console.log(" ".repeat(spaces), parseTree);
  switch (parseTree.constructor) {
    case Parser.QueryContext: {
      return parseTree2QueryInner(parseTree.getChild(0));
    }
    case Parser.AlternativeExpressionContext: {
      const t = parseTree as Parser.AlternativeExpressionContext;
      return {
        test: "or",
        children: t.children.map(c => parseTree2QueryInner(c))
      } as QEngine.OrTest;
    }

    case Parser.BinaryExpressionContext: {
      const t = parseTree as Parser.BinaryExpressionContext;

      const left = parseTree2QueryInner(t._left);
      const right = parseTree2QueryInner(t._right);

      switch (t._op.constructor) {
        case Parser.OrBinaryContext: {
          return {
            test: "or",
            children: [left, right]
          } as QEngine.OrTest;
        }

        case Parser.AndBinaryContext: {
          return {
            test: "and",
            children: [left, right]
          } as QEngine.AndTest;
        }
        default: {
          return;
        }
      }
    }

    case Parser.ParenExpressionContext: {
      const tree = parseTree as Parser.ParenExpressionContext;

      return parseTree2QueryInner(tree._expr);
    }

    case Parser.NotExpressionContext: {
      const t = parseTree as Parser.ParenExpressionContext;
      return (
        t._expr &&
        ({
          test: "negation",
          child: parseTree2QueryInner(t._expr)
        } as QEngine.NegationTest)
      );
    }

    case Parser.IdentifierExpressionContext: {
      return {
        test: "array",
        op: QEngine.ArrayOp.some,
        child: {
          test: "like",
          value: parseTree.text
        } as QEngine.LikeTest
      };
    }

    default: {
      return;
    }
  }
};

export const parseTree2Query = (parseTree: ParseTree) => {
  // console.log("=".repeat(80));
  if (parseTree.text === "<EOF>") {
    return {
      test: "true"
    } as any;
  }

  return {
    test: "map",
    op: QEngine.MapOp.specific_key,
    key: "tags",
    child: parseTree2QueryInner(parseTree)
  } as any;
};
