import * as React from "react";
import * as Ui from "semantic-ui-react";
import isImage from "is-image";
import {
  Editor,
  Plugin,
  getEventTransfer,
  RenderBlockProps
} from "slate-react";
import { BlockType } from "../schema";
import { css } from "emotion";

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) => {
  switch (p.node.type) {
    case BlockType.Image: {
      const url = p.node.data.get("url");
      return (
        <div {...p.attributes} className={css``}>
          <Ui.Image alt="" src={url} />
        </div>
      );
    }

    default: {
      return next();
    }
  }
};

const onPaste = (event: React.ClipboardEvent, editor: Editor, next: any) => {
  const transfer = getEventTransfer(event) as any;
  const { type, text } = transfer;
  if (type !== "text" && type !== "html") return next();
  if (!isImage(text)) return next();
  event.preventDefault();

  editor.insertBlock({
    type: BlockType.Image,
    data: {
      url: text
    }
  });
};

export const ImagePlugin = {
  renderBlock,
  onPaste
} as Plugin;
