import * as React from "react";
import { DocStoreState } from "containers/docStore";
import { css } from "emotion";
import { UserStoreDispatchCtx } from "containers/userStore";
import { Link } from "react-router-dom";
import * as Ui from "semantic-ui-react";

interface Props {
  docState: DocStoreState;
  rawSearch: string;
  setRawSearch: (s: string) => void;
}

const SavedSearches: React.SFC<Props> = p => {
  const userCtx = React.useContext(UserStoreDispatchCtx);
  return (
    !userCtx.state.profile.savedSearches.isEmpty() && (
      <Ui.Segment raised>
        <h2>Saved searches</h2>
        <Ui.List selection>
          {userCtx.state.profile.savedSearches.map(s => (
            // <li onClick={() => p.setRawSearch(s)}>
            //   <Link to="/">{s}</Link>
            // </li>

            <Ui.List.Item onClick={() => p.setRawSearch(s)}>
              <Link to="/">{s}</Link>
            </Ui.List.Item>
          ))}
        </Ui.List>
      </Ui.Segment>
    )
  );
};

export default SavedSearches;
