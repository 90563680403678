import { Editor } from "slate-react";
import { MarkType } from "../schema";

import { Map } from "immutable";
import { isKeyHotkey } from "is-hotkey";

const hotKeys = Map({
  "mod+b": MarkType.Bold,
  "mod+i": MarkType.Italic,
  "mod+u": MarkType.Underlined
});
const boundHotKeys = hotKeys.mapKeys(k => isKeyHotkey(k));

export const onKeyDown = (event: any, editor: Editor, next: any) => {
  const mark = boundHotKeys.find((v, k) => k(event as any));
  if (!mark) {
    return next();
  }

  event.preventDefault();
  editor.toggleMark(mark);
  return true;
};
