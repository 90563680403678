import * as Im from "immutable";
import { Value } from "slate";

interface IDocument {
  body: Value;
  metadata: Im.Map<string, any>;
  id?: string;
  created: Date;
  last_modified: Date;
}

export const initValue = (tags: string[]) =>
  Value.fromJSON({
    object: "value",
    document: {
      object: "document",
      data: {},
      nodes: [
        {
          object: "block",
          type: "paragraph",
          data: {},
          nodes: [
            {
              object: "text",
              text: "",
              marks: []
            }
          ]
        },
        {
          object: "block",
          type: "paragraph",
          data: {},
          nodes: [].concat.apply(
            [],
            tags.map(tag => [
              {
                object: "inline",
                type: "simpletag",
                data: { text: tag },
                nodes: []
              },
              {
                object: "text",
                text: " ",
                marks: []
              }
            ])
          )
          // nodes2: [
          //   {
          //     object: "text",
          //     text: "",
          //     marks: []
          //   },
          //   {
          //     object: "inline",
          //     type: "simpletag",
          //     data: {},
          //     nodes: [
          //       {
          //         object: "text",
          //         text: "a",
          //         marks: []
          //       }
          //     ]
          //   },
          //   {
          //     object: "text",
          //     text: " ",
          //     marks: []
          //   },
          //   {
          //     object: "inline",
          //     type: "simpletag",
          //     data: {},
          //     nodes: [
          //       {
          //         object: "text",
          //         text: "b",
          //         marks: []
          //       }
          //     ]
          //   },
          //   {
          //     object: "text",
          //     text: " ",
          //     marks: []
          //   }
          // ]
        }
      ]
    }
  } as any);

export const Document = Im.Record<IDocument>({
  id: "",
  body: initValue([]),
  metadata: Im.Map(),
  created: new Date(),
  last_modified: new Date()
});

export type TDocument = Im.Record<IDocument> & Readonly<IDocument>;

export type TDocumentCollection = Im.List<TDocument>;
export type TDocumentMap = Im.Map<string, TDocument>;

// Profile
interface IProfile {
  savedSearches: Im.Set<string>;
}

export const Profile = Im.Record<IProfile>({
  savedSearches: Im.Set()
});

export type TProfile = Im.Record<IProfile> & Readonly<IProfile>;

// User
interface IUser {
  id?: string;
  username: string;
  email: string;
  profile: TProfile;
}

export const User = Im.Record<IUser>({
  id: "",
  username: "",
  email: "",
  profile: Profile()
});

export type TUser = Im.Record<IUser> & Readonly<IUser>;
