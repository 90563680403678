import * as React from "react";
import { RenderBlockProps } from "slate-react";
import styled from "@emotion/styled";
import { Editor } from "slate-react";

import { BlockType, listTypes } from "../schema";
import { onKeyDown } from "./hotkeys";
import "./style.css";

const Ul = styled.ul`
  margin: 0px;

  p {
    margin-bottom: 0px;
  }
`;

const Ol = Ul.withComponent("ol");

const List = (p: RenderBlockProps, editor: Editor, next: any) => {
  switch (p.node.type as BlockType) {
    case BlockType.ListItem: {
      return <li {...p.attributes} children={p.children} />;
    }
    case BlockType.ListUnordered: {
      return <Ul {...p.attributes} children={p.children} />;
    }
    case BlockType.ListOrdered: {
      return <Ol {...p.attributes} children={p.children} />;
    }
    default: {
      return undefined;
    }
  }
};

const renderBlock = (p: RenderBlockProps, editor: Editor, next: any) =>
  listTypes.includes(p.node.type as BlockType) ? <List {...p} /> : next();

export const ListPlugin = {
  onKeyDown,
  renderBlock
};
