import * as React from "react";
import * as queryString from "query-string";

export const useAfterQuiet = (
  f: (a: () => void) => void,
  deps: ReadonlyArray<any>,
  timeout: number,
  nullDeps: ReadonlyArray<any> = []
) => {
  const [isDirty, setDirty] = React.useState(false);
  const [isFirstRun, setFirstRun] = React.useState(true);
  React.useEffect(() => {
    if (nullDeps.some(d => d == null)) {
      return;
    }

    if (isFirstRun) {
      setFirstRun(false);
      return;
    }

    setDirty(true);
    const timer = setTimeout(() => {
      f(() => setDirty(false));
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, deps);

  return isDirty;
};

export const useDoOnTimer = (
  f: () => void,
  deps: ReadonlyArray<any>,
  timeout: number
) => {
  const [isTimerRunning, setIsTimerRunning] = React.useState(false);

  React.useEffect(() => {
    if (isTimerRunning) {
      return;
    }
    setIsTimerRunning(true);
    setTimeout(() => {
      setIsTimerRunning(false);
      f();
    }, timeout);
  }, deps);
};

export const useStateInQueryString = (key: string, initialValue: any) => {
  const [val, setVal] = React.useState<typeof initialValue>(initialValue);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    // Load
    const qp = queryString.parse(window.location.search.slice(1));

    if ("q" in qp) {
      setVal(qp["q"] as any); // TODO: check
    }
    setIsLoaded(true);
  }, []);

  React.useEffect(() => {
    if (!isLoaded) {
      return;
    }
    // Set to URL
    let data = queryString.parse(window.location.search) as object;
    data[key] = val;
    data["v"] = 1;
    window.history.pushState({}, "", "?" + queryString.stringify(data));
  }, [val]);

  return [val, setVal];
};
