import * as React from "react";
import { DocStoreState } from "containers/docStore";
import Alphabetical from "./Alphabetical";
import SavedSearches from "./SavedSearches";

interface Props {
  docState: DocStoreState;
  rawSearch: string;
  setRawSearch: (s: string) => void;
}

const TagViz: React.SFC<Props> = p => {
  return (
    <div>
      <SavedSearches {...p} />
      <Alphabetical {...p} />
    </div>
  );
};

export default TagViz;
