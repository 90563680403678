import stringSimilarity from "string-similarity";

export interface EqualTest {
  test: "equal";
  value: any;
}

export interface GreaterThanTest {
  test: "greaterThan";
  value: any;
}

export interface LessThanTest {
  test: "lessThan";
  value: any;
}

export interface LikeTest {
  test: "like";
  value: any;
  treshold?: number;
}

export interface FunctionTest {
  test: "function";
  function: (v: any) => boolean;
}

type AtomTests =
  | EqualTest
  | GreaterThanTest
  | LessThanTest
  | LikeTest
  | FunctionTest
  | TrueTest;

function testAtom(test: AtomTests, data: any) {
  switch (test.test) {
    case "equal": {
      return;
    }
    case "lessThan": {
      return data < test.value;
    }
    case "greaterThan": {
      return data > test.value;
    }
    case "like": {
      const treshold = Math.min(
        0.8,
        test.treshold || 0.15 + (0.5 * test.value.length) / 7
      );
      // const treshold = test.treshold || 0.65;
      const similarity = stringSimilarity.compareTwoStrings(test.value, data);
      return similarity >= treshold;
    }
    case "function": {
      return test.function(data);
    }
    case "true": {
      return true;
    }
    default: {
      return false;
    }
  }
}

export interface AndTest {
  test: "and";
  children: Test[];
}

export interface OrTest {
  test: "or";
  children: Test[];
}

export interface TrueTest {
  test: "true";
}

export interface NegationTest {
  test: "negation";
  child: Test;
}

export enum ArrayOp {
  some = "some",
  every = "every",
}

export interface ArrayTest {
  test: "array";
  child: Test;
  op: ArrayOp;
}

export enum MapOp {
  any_key = "any_key",
  specific_key = "specific_key",
}

export interface MapTest {
  test: "map";
  child: Test;
  key: string;
  op: MapOp;
}

// ----------------------------------------------------------------- QUERY FUNC

export type Test =
  | AtomTests
  | MapTest
  | ArrayTest
  | NegationTest
  | AndTest
  | OrTest;

export function testQuery(test: Test, data: any) {
  if (!test) {
    return null;
  }

  switch (test.test) {
    case "map": {
      switch (test.op) {
        case MapOp.specific_key: {
          if (!(test.key in data)) {
            return false;
          }
          return testQuery(test.child, data[test.key]);
        }
        case MapOp.any_key: {
          return Object.values(data).some(testQueryCurry(test.child));
        }
        default: {
          console.error("Unexpected key as MapOp: ", test);
          return;
        }
      }
    }
    case "array": {
      switch (test.op) {
        case ArrayOp.some: {
          return (data as any[]).some(testQueryCurry(test.child));
        }
        case ArrayOp.every: {
          return (data as any[]).every(testQueryCurry(test.child));
        }
        default: {
          console.error("Unexpected key as ArrayOp: ", test);
          return;
        }
      }
    }
    case "negation": {
      return !testQuery(test.child, data);
    }
    case "and": {
      return test.children.every((s) => s && testQuery(s, data));
    }
    case "or": {
      return test.children.some((s) => s && testQuery(s, data));
    }
    default: {
      return testAtom(test, data);
    }
  }
}

export const testQueryCurry = (test: Test) => (data: any) =>
  testQuery(test, data);
