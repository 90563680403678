import * as React from "react";
import * as Ui from "semantic-ui-react";
import * as T from "types";
import * as Api from "api";
import Editor from "./Editor";
import { useAfterQuiet } from "containers/customHooks";
import { DocStoreDispatchCtx } from "containers/docStore";
import { PositiveNegativeModal } from "containers/Components";
import { css } from "emotion";
import { UserStoreDispatchCtx } from "containers/userStore";
import { getCookieValue } from "utils/misc";
import { BlockType } from "./Editor/schema";

interface Props {
  doc: T.TDocument;
  setSearchQuery: (query: string) => void;
}

const Document: React.SFC<Props> = p => {
  const actions = React.useContext(DocStoreDispatchCtx);
  const userActions = React.useContext(UserStoreDispatchCtx);
  const [isSavingError, setIsSavingError] = React.useState(false);
  const editorRef = React.useRef();

  // Load doc
  React.useEffect(() => {
    if (p.doc.body) {
      return;
    }
    actions.loadDocBody(p.doc);
  }, [p.doc.body != null]);

  const onDocUpdate = (d: T.TDocument) => {
    actions.setDoc(d);
  };

  // save doc

  const isDirty = useAfterQuiet(
    setNotDirty => {
      Api.patchDoc(p.doc)
        .then(() => {
          setNotDirty();
          setIsSavingError(false);
        })
        .catch(() => {
          setIsSavingError(true);
          alert(
            "Couldn't save document. Please check your internet connection"
          );
        });
    },
    [p.doc.body && p.doc.body.document, isSavingError],
    1000,
    [p.doc.body && p.doc.body.document]
  );

  // delete doc
  const onDelete = () => {
    actions.deleteDoc(p.doc);
  };

  const onUploadImage = e => {
    Api.uploadImage(e.target.files).then(d => {
      const imgUrl = d["url"];
      (editorRef.current as any).editor.command(editor => {
        editor.insertBlock({
          type: BlockType.Image,
          data: {
            url: imgUrl
          }
        });
      });
    });
  };

  console.log("rerendering doc");

  return (
    <div>
      {isSavingError && (
        <Ui.Message error>
          Could not save note
          <Ui.Button primary onClick={() => setIsSavingError(false)}>
            Retry
          </Ui.Button>
        </Ui.Message>
      )}

      {p.doc && p.doc.body && (
        <Editor
          setSearchQuery={p.setSearchQuery}
          doc={p.doc}
          onDocUpdate={onDocUpdate}
          editorRef={editorRef}
        />
      )}

      <div
        className={css`
          position: relative;
          width: 100%;
          min-height: 1em;
          margin-top: 0.5em;
        `}
      >
        {/* hamburger menu */}
        <div style={{ position: "absolute", right: "0", bottom: 0 }}>
          {isDirty && <Ui.Icon name="save outline" />}
          <Ui.Dropdown icon="bars" direction="left">
            <Ui.Dropdown.Menu>
              <Ui.Dropdown.Item icon="image outline">
                <label>
                  Upload Image
                  <input
                    className={css`
                      display: none;
                    `}
                    type="file"
                    onChange={onUploadImage}
                  />
                </label>
              </Ui.Dropdown.Item>
              <PositiveNegativeModal
                trigger={p => (
                  <Ui.Dropdown.Item {...p} icon="trash" text="Delete" />
                )}
                onPositive={onDelete}
                description={
                  <span>
                    <Ui.Header icon="trash" content="Delete Note" />,
                    <Ui.Modal.Content>
                      <p>Do you want to delete this note?</p>
                    </Ui.Modal.Content>
                  </span>
                }
              />
            </Ui.Dropdown.Menu>
          </Ui.Dropdown>
        </div>

        {/* Dates */}
        <div
          className={css`
            font-size: 0.75em;
            color: gray;
            width: 90%;
          `}
        >
          <span
            className={css`
              display: inline-block;
            `}
          >
            Created: {p.doc.created.toLocaleString()}
          </span>
          {"     "}
          <span
            className={css`
              display: inline-block;
            `}
          >
            Last Edited: {p.doc.last_modified.toLocaleString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Document);
